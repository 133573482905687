import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { COLORS } from "constants/Colors";

export const RayzeAccountTransactionCard = ({ item, onPress }) => {
  const { date, recurring, amount, type, bank = {}, nonprofit, employee = false, from = {}, to = {}, status, balance = 5 } = item;

  let description;
  let title;
  let color = COLORS.primary.green;
  if (type === "donation") {
    // title = nonprofit?.name;
    color = COLORS.primary.error;
    description = "Donation to " + nonprofit?.name;
  } else if (employee && from?.name) { // from company to user wallet
    // title = from?.name;
    description = "Transfer from " + from?.name;
  } else if (employee && to?.names?.length) { // from wallet to employees
    // title = to?.name;
    description = "Wallet Transfer to " + to?.names.join(", ");
    color = COLORS.primary.error;
  } else if (to?.names?.length) { // from bank acct to employees
    description = "Bank Transfer to " + to?.names.join(", ");
    color = COLORS.primary.black;
  } else { // bank deposit
    // title = "Bank Transfer";
    description = "Bank Transfer from acct #" + bank?.last4;
  }

  return (
    <TableRow hover onClick={onPress}>
      <TableCell>
        <PrimaryText fontSize={18} fontWeight={900}>
          {date.toDateString()}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={18} fontWeight={900}>
          {description}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {type}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500} color={color}>
          {dollarFormatter.format(amount)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {dollarFormatter.format(balance)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {status}
        </PrimaryText>
      </TableCell>
    </TableRow>
  );
};
