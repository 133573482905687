import { gendersOptions, ROLES } from "constants/Data"

export const unique = (arr) => {
  return [...new Map(arr.map(v => [v?.id, v])).values()]
};

export const validateUrl = (string) => {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  if (res !== null) return null
  else return "Enter a valid url"
}

export const getTimeDifference = (dateTime, end) => {
  if (!dateTime) {
    return { value: -1, suffix: "" }
  }
  var date = dateTime.getTime()
  let diff = date - end.getTime()
  var msec = diff

  let percentageDifference = (diff / date) * 100
  if (msec < 0) {
    return { value: 0, suffix: "" }
  }
  var years = Math.floor(msec / 1000 / 60 / (60 * 24 * 365))
  msec -= years * 1000 * 60 * 60 * 24 * 365
  var days = Math.floor(msec / 1000 / 60 / (60 * 24))
  msec -= days * 1000 * 60 * 60 * 24
  var hh = Math.floor(msec / 1000 / 60 / 60)
  msec -= hh * 1000 * 60 * 60
  var mm = Math.floor(msec / 1000 / 60)
  msec -= mm * 1000 * 60
  var ss = Math.floor(msec / 1000)
  msec -= ss * 1000

  if (years > 0) {
    return { value: years, suffix: "y", percentageDifference }
  } else if (days > 0) {
    return { value: days, suffix: "d", percentageDifference }
  } else if (hh > 0) {
    return { value: hh, suffix: "h", percentageDifference }
  } else if (mm > 0) {
    return { value: mm, suffix: "m", percentageDifference }
  }

  return { value: 0, suffix: "m", percentageDifference: 100 }
}

export const getUsersName = (user) => {
  return user?.firstName + " " + user?.lastName
}

export const getUsersGender = (user) => {
  return gendersOptions.find(x => x.id===user?.gender)?.name || 'X'
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateEmailDoesntExist(email, members) {
  return !!members.find(x => x.email===email)
}

export var dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // notation: 'compact',
  // maximumSignificantDigits: 3
});

export const getRole = (user) => {
  const found = ROLES.find(x => x.value === user?.role)
  return {
      value: found?.value,
      label: found?.label
  }
}

export const getCityStateZip = (item) => {
  let {city, state, zipCode} = item?.address
  return (capitalize(city)+', '+capitalize(state))
}

export const getFullLocation = (item) => {
  let { city, state, zipCode, line1 } = item?.address
  
  return (`${line1 ? capitalize(line1) + ", " : ''}${capitalize(city)}, ${capitalize(state)} ${zipCode || ''}`)
}

export const getPbcLocation = (item) => {
  let { city, state, zipCode, street } = item?.address
  
  return (`${street ? capitalize(street) + ", " : ''}${capitalize(city)}, ${capitalize(state)} ${zipCode || ''}`)
}

function capitalize(text) {
  return text.charAt(0).toUpperCase()+text.slice(1)
}