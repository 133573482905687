import React, { useEffect, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { ProfilePic } from "components/Pics/ProfilePic";
import { CustomInput } from "components/Inputs/CustomInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import exportCSV from "utils/export-csv";
import { dollarFormatter } from "utils/helpers";
import useMediaQuery from "hooks/useMediaQuery";
import { useWallet } from "context/WalletContext";
import { RayzeAccountCard } from "components/Cards/RayzeAccountCard";
import { View } from "react-native-web";
import { Links } from "constants/Links";

export function Wallet() {
  const navigate = useNavigate();
  const {
    rayzeWalletStripeAccount,
    setGetWalletAccounts,
    allWalletAccounts,
    sorting,
    setSorting,
    setSelectedAccount
  } = useWallet();
  const { getResponsiveValue } = useMediaQuery();

  console.log('rayzeWalletStripeAccount', rayzeWalletStripeAccount)

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setGetWalletAccounts(true);
    let temp = [...allWalletAccounts];
    if (search) {
      temp = temp.filter(
        (item) =>
          // item?.user?.email?.toLowerCase().includes(search.toLowerCase()) ||
          item?.user?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.user?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.org?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }
    // if (startDate) {
    //   temp = temp.filter((item) => item.lastUpdated >= startDate);
    // }
    // if (endDate) {
    //   temp = temp.filter((item) => item.lastUpdated <= endDate);
    // }
    setFiltered(temp);
  }, [search, startDate, endDate, allWalletAccounts]);

  const resetFilters = () => {
    setSearch("");
    setStartDate(null);
    setEndDate(null);
  };

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  // Export fundraisers in CSV
  const exportFundraisers = () => {
    const currentData = currentTableData();

    if (!currentData?.length) return;

    const dataToExport = currentData.map((row) => {
      return {
        NAME: row?.user
          ? `${row?.user?.firstName} ${row?.user?.lastName}`
          : row?.name,
        ORGANIZATION: row?.org?.name,
        ACTIVE: row?.active ? "Y" : "",
        BACKERS: row?.backers?.length,
        "AMOUNT RAISED": dollarFormatter.format(row?.totalDonations),
      };
    });

    exportCSV({
      data: dataToExport,
      filename: "Fundraisers",
    });
  };

  const handleSort = (name) => {
    if (name === sorting.orderBy) {
      setSorting({
        orderBy: name,
        orderIn: sorting.orderIn === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({
        orderBy: name,
        orderIn: "asc",
      });
    }

    setCurrentPage(0);
  };
  // console.log(filtered.map((item) => item.totalDonations));
  // console.log(filtered?.length, rowsPerPage, currentPage);

  const sumOfWalletAccounts = allWalletAccounts.reduce(
    (accumulator, object) => accumulator + object.balance,
    0
  );
  const sumOfWalletFees = allWalletAccounts.reduce(
    (accumulator, object) => accumulator + object.fees,
    0
  );

  const statistics = [
    {
      title: "Rayze Stripe Account",
      value: dollarFormatter.format(rayzeWalletStripeAccount?.amount),
      description: "pulled directly from our Stripe Account"
    },
    {
      title: "Rayze Customer Accounts Balance",
      value: dollarFormatter.format(sumOfWalletAccounts),
      description: "summed from all customer accounts"
    },
    {
      title: "Rayze Customer Fees Balance",
      value: dollarFormatter.format(sumOfWalletFees),
      description: "summed from all customer accounts"
    },
  ];


  const styles = getStyles({ getResponsiveValue });

  return (
    <>
      <View style={[styles.info, { marginTop: 24 }]}>
        <View style={styles.stats}>
          {statistics.map((item, i) => {
            const { title, value, description } = item;
            return (
              <View
                style={styles.statContainer}
                key={i}
              >
                <PrimaryText
                  fontSize={18}
                  fontWeight={400}
                  style={styles.header}
                >
                  {title}
                </PrimaryText>
                <PrimaryText
                  fontSize={24}
                  fontWeight={700}
                  style={styles.header}
                >
                  {value}
                </PrimaryText>
                <PrimaryText
                  fontSize={12}
                  fontWeight={400}
                  style={{ marginTop: 8 }}
                >
                  {description}
                </PrimaryText>
              </View>
            );
          })}
        </View>
      </View>

      <ShadowContainer style={styles.container}>
        {/** HEADER */}

        <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
          Rayze Accounts
        </PrimaryText>



        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ sm: "center" }}
          justifyContent="space-between"
          width="100%"
          mb={40}
          mt={14}
          gap={12}
        >
          <CustomInput
            placeholder="Search"
            style={{
              flexGrow: 1,
              maxWidth: getResponsiveValue({ xs: "100%", sm: 400 }),
            }}
            value={search}
            onChangeText={setSearch}
          />

          {/* Export Button */}
          {/* <PrimaryButton
            title="Export Report"
            onPress={exportFundraisers}
            icon={<FiUpload color="white" style={{ marginRight: 10 }} />}
            style={{ marginBottom: 0, height: 32 }}
          /> */}
        </Box>

        {/** TABLE */}
        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "left" }}>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sorting.orderBy === "balance"}
                    direction={sorting.orderIn}
                    onClick={() => handleSort("balance")}
                    sx={{
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "& .MuiTableSortLabel-icon": {
                        color: "#fff !important",
                      },
                    }}
                  >
                    Balance
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((item) => (
                  <RayzeAccountCard
                    key={item.id}
                    item={item}
                    onPress={() => {
                      setSelectedAccount(item);
                      navigate(Links.WalletDetail);
                    }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <MuiTablePagination
          count={filtered?.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e.target.value);
            setCurrentPage(0);
          }}
        />
      </ShadowContainer>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </>
  );
}

// Temporary table rows
const Rows = () => (
  <TableRow>
    <TableCell
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ProfilePic size={50} />
      <PrimaryText fontSize={20} fontWeight={900} style={{ marginLeft: 14 }}>
        John Doe
      </PrimaryText>
    </TableCell>

    {/* Email */}
    <TableCell>
      <PrimaryText
        fontSize={16}
        fontWeight={400}
        style={{ color: COLORS.primary.blue }}
      >
        email@address.com
      </PrimaryText>
    </TableCell>

    {/* Latest donation */}
    <TableCell>
      <PrimaryText fontSize={16} fontWeight={400}>
        01/01/2023
      </PrimaryText>
    </TableCell>

    {/* Backers */}
    <TableCell>
      <PrimaryText fontSize={16} fontWeight={400}>
        195
      </PrimaryText>
    </TableCell>

    {/* Amount raised */}
    <TableCell>
      <PrimaryText fontSize={16} fontWeight={400}>
        $182,029
      </PrimaryText>
    </TableCell>
  </TableRow>
);

const getStyles = ({ getResponsiveValue }) => StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
  statContainer: {
    alignItems: "center"
  },
  stats: {
    flex: 1,
    flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
    padding: 27,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    gap: 12
  },
  info: {
    flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
    marginBottom: 16,
    gap: 6
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6
  },
  infoText: {
    flex: 1
  },
});
