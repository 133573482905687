import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { ProfilePic } from "components/Pics/ProfilePic";
import { CustomInput } from "components/Inputs/CustomInput";
import { DateInput } from "components/Inputs/DateInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { FiCopy, FiUpload } from "react-icons/fi";
import { useFundraisers } from "context/FundraiserContext";
import { useOrganization } from "context/OrganizationContext";
import { FundraisingCard } from "components/Cards/FundraisingCard";
import MuiCard from "components/Common/MuiCard";
import { dollarFormatter, getCityStateZip, getUsersName } from "utils/helpers";
import { FundraisingBackerCard } from "components/Cards/FundraisingBackerCard";
import { View } from "react-native-web";
import { capitalize, formattedDate, formattedPhoneNumber } from "utils";
import moment from "moment";
import { NavHeader } from "components/Headers/NavHeader";
import { useNavigate } from "react-router-dom";
import exportCSV from "utils/export-csv";
import { CopyAllOutlined, QrCode } from "@mui/icons-material";
import { QRCodeCanvas } from "qrcode.react";
import useMediaQuery from "hooks/useMediaQuery";
import { useWallet } from "context/WalletContext";
import { RayzeAccountTransactionCard } from "components/Cards/RayzeAccountTransactionCard";

export function WalletDetail() {
	const navigate = useNavigate();
	const { getResponsiveValue } = useMediaQuery();
	const {
		walletTransactions,
		selectedAccount,
	} = useWallet();
	const { selectedOrg } = useOrganization();

	const qrRef = useRef();

	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [filtered, setFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

	// console.log("fundraiserDonations", fundraiserDonations);

	useEffect(() => {
		let temp = [...walletTransactions];
		if (search) {
			// temp = temp.filter((item) =>
			// 	item?.user
			// 		? item?.user?.firstName
			// 			?.toLowerCase()
			// 			.includes(search.toLowerCase()) ||
			// 		item?.user?.lastName
			// 			?.toLowerCase()
			// 			.includes(search.toLowerCase()) ||
			// 		item?.user?.email?.toLowerCase().includes(search.toLowerCase())
			// 		: (item?.name &&
			// 			item?.name.toLowerCase().includes(search.toLowerCase())) ||
			// 		(item?.email &&
			// 			item?.email.toLowerCase().includes(search.toLowerCase()))
			// );
			setCurrentPage(0);
		}
		setFiltered(temp);
	}, [search, walletTransactions]);

	function onBack() {
		navigate(-1);
	}

	const currentTableData = () => {
		const firstPageIndex = currentPage * rowsPerPage;
		const lastPageIndex = firstPageIndex + rowsPerPage;
		return filtered.slice(firstPageIndex, lastPageIndex);
	};

	// Export fundraiser backers in CSV
	const exportBackers = async () => {
		const currentData = currentTableData();

		if (!currentData?.length) return;

		const dataToExport = await Promise.all(
			currentData.map(async (item) => {
				const { email, date, amount = 0, fee = 0, userId } = item || {};
				const amountGiven = amount - fee;

				let user = item?.user;

				// const setUser = (data) => {
				// 	user = data || { firstName: "Anonymous", lastName: "" };
				// };

				// if (!user) {
				// 	userId && (await getUser(userId, setUser));
				// }

				return {
					NAME: user ? `${user?.firstName} ${user?.lastName}` : item?.name,
					EMAIL: email || user?.email,
					DATE: moment(date?.toDate?.()).format("MM/DD/YYYY"),
					"AMOUNT GIVEN": dollarFormatter.format(amountGiven)
				};
			})
		);
		exportCSV({
			data: dataToExport,
			filename: "Fundraiser Donors"
		});
	};

	const downloadQRCode = () => {
		let canvas = qrRef.current.querySelector("canvas");
		let image = canvas.toDataURL("image/png");
		let anchor = document.createElement("a");
		anchor.href = image;
		anchor.download = `qr-code.png`;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	};

	const { user = {}, org = {} } = selectedAccount || {};

	let userDetails = [
		{ title: "Account Balance", value: dollarFormatter.format(selectedAccount.balance) },
		// { title: "Email", value: user.email },
		// { title: "Phone", value: formattedPhoneNumber(user.phone) },
		{ title: "Gender:", value: capitalize(user.gender) },
		{
			title: "Location:",
			value: user?.city ? user?.city + ", " + user?.state : user?.zipCode
		},
		{
			title: "Birth Year:",
			value: user?.birthday
				? moment(user?.birthday.toDate()).format("YYYY")
				: ""
		},
		{ title: "Join Date:", value: formattedDate(user.createdAt) }
		// { title: "Champion Link:", value: , url: true },
	];

	if (org.name) {
		userDetails = [
			{ title: "Account Balance", value: dollarFormatter.format(selectedAccount.balance) },
			{ title: "EIN", value: org.ein },
			{ title: "Phone", value: formattedPhoneNumber(org.phone) },
			{ title: "Location", value: getCityStateZip(org) },
			{ title: "Join Date", value: formattedDate(org.createdAt) }
		]
	}

	//total sum of fundraiserDonations
	const totalAmounts = 0
	const totalDonations = totalAmounts;

	const BASE_URL = `https://dev.donate.rayzeapp.com/${user?.username}`;

	// console.log(totalAmounts, totalFees, totalDonations);
	console.log('user', walletTransactions)

	return (
		<View style={{ width: "100%", height: "100%" }}>
			<NavHeader
				title={"Wallet Details"}
				onBack={onBack}
			/>

			<View
				style={{
					flexDirection: getResponsiveValue({ xs: "column", xl: "row" }),
					alignItems: "flex-start",
					marginBottom: 12,
					marginTop: 20,
				}}
			>
				<View
					style={{
						flex: 1,
						flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
						alignItems: getResponsiveValue({ xs: "center", md: "flex-start" }),
						gap: 24,
					}}
				>

					<ProfilePic
						uri={user?.profilePic || org?.profilePic}
						size={215}
					/>

					<View
						style={{
							alignItems: getResponsiveValue({
								xs: "center",
								md: "flex-start",
							})
						}}
					>
						{user.firstName ? (
							<PrimaryText
								fontSize={getResponsiveValue({ xs: 20, md: 32 })}
								fontWeight={900}
								style={styles.title}
							>
								{getUsersName(user)}
								{" - "}
								<PrimaryText
									fontSize={getResponsiveValue({ xs: 16, md: 32 })}
									fontWeight={900}
									style={[styles.title, { color: COLORS.primary.neutral_500 }]}
								>
									@{user?.username}
								</PrimaryText>
							</PrimaryText>
						) : (
							<PrimaryText
								fontSize={getResponsiveValue({ xs: 20, md: 32 })}
								fontWeight={900}
								style={styles.title}
							>
								{org?.name}
							</PrimaryText>
						)}
						<View
							style={{
								flexDirection: getResponsiveValue({ xs: "column", sm: "row" }),
								alignItems: "center"
							}}
						>
							<View >
								<PrimaryText
									fontSize={getResponsiveValue({ xs: 20, md: 24 })}
									fontWeight={400}
									style={{ marginVertical: 12 }}
								>
									Account Information
								</PrimaryText>
								<View style={{flex: 1}}>
								{userDetails.map((u, i) => {
									const { title, value, url = false } = u;
									if (!value) return null;
									return (
										<View
											style={styles.infoTextRow}
											key={i}
										>
											<View
												style={{ marginRight: 12 }}
												className="text-right mr-4"
											>
												<PrimaryText
													fontSize={18}
													fontWeight={400}
													style={{ width: 175, textAlign: "left" }}
												>
													{title}
												</PrimaryText>
											</View>

											<PrimaryText
												fontSize={18}
												fontWeight={900}
												style={u?.capitalize && { textTransform: "capitalize" }}
											>
												{value}
											</PrimaryText>
										</View>
									);
								})}
								</View>
							</View>

							{!selectedAccount.isOrg && (
								<View>
									<View
										style={{
											marginLeft: 24,
											alignItems: getResponsiveValue({
												xs: "justify-center",
												md: "flex-start"
											})
										}}
									>
										<PrimaryText
											fontSize={getResponsiveValue({ xs: 16, md: 24 })}
											fontWeight={400}
											style={{ marginVertical: 12 }}
										>
											Champion Link and QR Code
										</PrimaryText>
										<PrimaryButton
											title="Copy Link"
											onPress={() => {
												navigator.clipboard.writeText(
													`https://dev.donate.rayzeapp.com/${user?.username}`
												);
											}}
											style={{
												marginBottom: 12,
												backgroundColor: "transparent",
												borderWidth: 1,
												borderColor: COLORS.primary.darkGray,
												height: 32,
												width: undefined,
												paddingHorizontal: 24
											}}
											titleStyle={{ color: COLORS.primary.darkGray }}
											secondary={true}
											icon={
												<CopyAllOutlined
													style={{
														marginRight: 6,
														height: 20,
														width: 20,
														color: COLORS.primary.darkGray
													}}
												/>
											}
										/>
										<PrimaryButton
											title="Download QR Code"
											onPress={downloadQRCode}
											style={{
												marginBottom: 12,
												height: 32,
												width: undefined,
												paddingHorizontal: 24
											}}
											secondary={true}
											icon={
												<QrCode
													style={{
														marginRight: 6,
														height: 20,
														width: 20,
														color: COLORS.primary.white
													}}
													color={COLORS.primary.white}
												/>
											}
										/>
									</View>
								</View>
							)}
						</View>
					</View>

				</View>

				<View />

			</View>

			<ShadowContainer style={styles.container}>
				{/** HEADER */}
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					flexDirection={{ xs: "column", md: "row" }}
					width="100%"
					marginBottom={20}
					gap={20}
				>
					<Box style={{ flex: 1, width: "100%" }}>
						<PrimaryText
							fontSize={32}
							fontWeight={900}
							style={styles.title}
						>
							Transactions
						</PrimaryText>

						<CustomInput
							placeholder="Search"
							style={{
								flexGrow: 1,
								maxWidth: 550,
								marginTop: 14
							}}
							value={search}
							onChangeText={setSearch}
						/>
					</Box>

					{/* Export Button */}
					{/* <PrimaryButton
						title="Export Report"
						onPress={exportBackers}
						icon={
							<FiUpload
								color="white"
								style={{ marginRight: 10 }}
							/>
						}
						style={{ marginBottom: 0 }}
					/> */}
				</Box>

				{/** TABLE */}
				<TableContainer>
					<Table
						className="table"
						style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
					>
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Balance</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{currentTableData() &&
								currentTableData().map((item) => (
									<RayzeAccountTransactionCard
										item={item}
										key={item.id}
									/>
								))}
						</TableBody>
					</Table>


				</TableContainer>
				<MuiTablePagination
					count={filtered?.length}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					setCurrentPage={setCurrentPage}
					onRowsPerPageChange={(e) => {
						setRowsPerPage(e.target.value);
						setCurrentPage(0);
					}}
				/>
			</ShadowContainer>
			<Box
				ref={qrRef}
				display="none"
			>
				<QRCodeCanvas
					// id="qrCode"
					value={BASE_URL}
					size={300}
					bgColor={"white"}
					// imageSettings={{
					// 	src: rayzeLogo,
					// 	x: undefined,
					// 	y: undefined,
					// 	height: 75,
					// 	width: 75
					// }}
					level="H"
					style={{ display: "hidden" }}
				/>
			</Box>
			{loading && (
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.lightBlue}
				/>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.primary.white,
		position: "absolute",
		top: 0,
		bottom: 0
	},
	container: {
		flex: 1,
		width: "100%",
		flexDirection: "column"
	},
	infoTextRow: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 4,
	}
});
